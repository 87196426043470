import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const Schedule = () => {

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const navigate = useNavigate();
  const [pickUpType, setPickUpType] = useState("");
  const [containerCount, setContainerCount] = useState("");
  const [containerSize, setContainerSize] = useState("");
  const [pickUpFreq, setPickUpFreq] = useState("");
  const [pickUpDays, setPickUpDays] = useState("");
  const [hasDumpsterLock, setHasDumpsterLock] = useState(false);
  const [frontLoadRearLoad, setFrontLoadRearLoad] = useState("");
  const [hasSchedule, setHasSchedule] = useState(false);
  const [locationNameNumber, setLocationNameNumber] = useState("");
  const [address, setAddress] = useState("");
  const {state} = useLocation();
  const {id} = state;
  const ARROW = ">";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSchedule();
  }, [])

  const fetchSchedule = async() => {
    const response = await fetch(`/apischedule/${id}`);
    const schedule = await response.json();
      // 66 = location number, 36 = address, 21 = location code, 
  // 20 = pick up type, 33 = container count, 15 container size, 
  // 16 = pick up freq., 61 = pick up days, 44 = has dumpster lock
  // 45 = frontRear
    const locationNameNumber = schedule && schedule[66] ? schedule[66].value : "";
    const containerCount = schedule && schedule[33] ? schedule[33].value : "";
    const address = schedule && schedule[36] ? schedule[36].value : "";
    const containerSize = schedule && schedule[15] ? schedule[15].value : "";
    const pickUpFreq = schedule && schedule[16] ? schedule[16].value : "";
    const pickUpDays = schedule && schedule[61] ? schedule[61].value : "";
    const frontRearLoad = schedule && schedule[45] ? schedule[45].value : "";
    const pickUpType = schedule && schedule[20] ? schedule[20].value : "";
    const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : false;
    setLocationNameNumber(locationNameNumber);
    setContainerCount(containerCount);
    setFrontLoadRearLoad(frontRearLoad);
    setAddress(address);
    setHasDumpsterLock(!!hasDumpsterLock);
    setPickUpDays(pickUpDays);
    setPickUpFreq(pickUpFreq);
    setPickUpType(pickUpType);
    setContainerSize(containerSize);
    setHasSchedule(true);
  }

  return (
    <div className="schedule">{hasSchedule && (
      <div className="scheduleDetailsWrapper">
        <h2>Waste & Recycling Schedule <strong className="arrow">{ARROW}</strong> {!!locationNameNumber && locationNameNumber}</h2>
      <div>
        <p className="fieldLabel">Location Number: </p><p className="field">{locationNameNumber}</p>
      </div>
      <div>
        <p className="fieldLabel">Pick-Up Type: </p><p className="field">{pickUpType}</p>
      </div>
      <div>
        <p className="fieldLabel">Container Count: </p><p className="field">{containerCount}</p>
      </div>
      <div>
        <p className="fieldLabel">Container Size: </p><p className="field">{containerSize}</p>
      </div>
      <div>
        <p className="fieldLabel">Pick-Up Freqency: </p><p className="field">{pickUpFreq}</p>
      </div>
      <div>
        <p className="fieldLabel">Pick-Up Days: </p><p className="field">{pickUpDays}</p>
      </div>
      <div>
        <p className="fieldLabel">Front/Rear Load: </p><p className="field">{frontLoadRearLoad}</p>
      </div>
      </div>
    )}</div>
  )
}