import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import { Packer } from 'docx';
import { DocumentCreator } from './doc_generator';

class LocationPosterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'React',
    };
    this.generate = this.generate.bind(this);
    this.formattedSchedulesHeader = [
      ['Location', 'Pick Up Type', 'Container Count', 'Container Size', 'Pick Up Freq.', 'Pick Up Days', 'Has Dumpster Lock'],
    ]
  }


 

  generate = async () => {
    // pass props in to document creator to customize with WC data. 
    const documentCreator = new DocumentCreator();

    documentCreator.generateFromUrl(this.props.locationNumber, this.props.districtName, this.props.formattedSchedules, this.formattedSchedulesHeader)
  }

  render() {
    return (
      <div>
        <p>
          <button className="mainButton" onClick={this.generate}>Download Location Poster</button>
        </p>
      </div>
    );
  }
}

export default LocationPosterButton;

