import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import pdf from '../media/WC Client Portal Manual.pdf'
import { useDispatch } from 'react-redux';

export const Home = () => {
  
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };
  const [districts, setDistricts] = useState([]);
  const navigate = useNavigate();
  const {state} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDistricts();
  }, [])

  const fetchDistricts = async() => {
    const response = await fetch("/apidistricts");
    const districts = await response.json();
    if (districts && districts.sessionExpired) {
      logoutUser();
      return;
    }
    if (response.status === 200) {
      setDistricts(districts);
    }

  }

  const redirectToDistrictPage = (e, districtCode) => {
    navigate(`district/${districtCode}`, {state: {id: districtCode}});
  }

  return (
    <div className="homepage">
      <div className="userManualWrapper">
        <h1 style={{borderBottom: '1px solid #c0c0c0'}} className="heading">Click on the button below to download the user manual</h1>
        <a target="_blank" href={pdf} className="mainButton">Download User Manual</a>
      </div>
      <h2 className="heading">White Castle Disctrics - Click to View Schedules</h2>
      <table id="tableWrapper">
        <tbody>
        <tr>
          <th>District Full Name</th>
          <th>Label</th>
          {/* <th>Email District Schedules Excel</th>
          <th>Email District Schedules Word</th> */}
          <th>District Contact - Name</th>
          <th>District Contact - Phone</th>
          <th>District Contact - Email</th>
        </tr>
          {districts && districts.length > 0 && districts.map((district, idx) => {
            const districtCode = district && district[6].value ? district[6].value : null;
            return (
              <tr key={idx} onClick={(e) => redirectToDistrictPage(e, districtCode)} className="tableRow">
                <td className="tableCell">{district && district[21] ? district[21].value : ""}</td>
                <td className="tableCell">{district && district[11] ? district[11].value : ""}</td>
                {/* <td className="tableCell">{"Download Pick Up Schedules Excel"}</td>
                <td className="tableCell">{"Download Pick Up Schuedles Word"}</td> */}
                <td className="tableCell">{district && district[28] ? district[28].value : ""}</td>
                <td className="tableCell">{district && district[30] ? district[30].value : ""}</td>
                <td className="tableCell">{district && district[33] ? district[33].value : ""}</td>
              </tr>
            )
          })}
          </tbody>
      </table>
    </div>
  )
}