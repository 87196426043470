import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const Nav = ({currentUser}) => {

  const [user, setUser] = useState({})

  const navigate = useNavigate();

  useEffect(() => {
    if (!!currentUser) {
      setUser(currentUser);
    }
  }, [currentUser, user])

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const checkIfCurrentUser = async () => {
    
  }

  const handleLogoutUser = async (e) => {
    const response = await fetch('/apilogout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });
    const data = await response.json();
    if (data === true) {
      logoutUser();
    }
  }

  const hasCurrentUser = !!(user && user.userId > 0);

  const navigateHome = () => window.location = "/";

 return (
  <>
          {currentUser && currentUser.userId && (
            <div className="navWrapper">
              <NavLink  to="/"><img onClick={navigateHome} className="fusaLogo" src="https://res.cloudinary.com/aptquirks/image/upload/v1680292865/facilities_logo_fxaz7e.png"></img></NavLink>
              <ul className="navigation">
              <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to="/locations"><li className="navItem">Locations</li></NavLink>
              <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to="/servicetickets"><li className="navItem">Service Requests</li></NavLink>
              {/* <NavLink to="/createTicket"><li className="navItem">Create Service Request</li></NavLink> */}
              <NavLink   className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to="/Schedules"><li className="navItem">Schedules</li></NavLink>
                {hasCurrentUser && (
                  <a onClick={handleLogoutUser}><li className="navItem">logout</li></a>
                )}
                {!hasCurrentUser && (
                  <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to="/login"><li className="navItem">login</li></NavLink>
                )}
              </ul>
              <div style={{width: '220px', textAlign: 'right'}}><img style={{width: '60px', marginRight: '20px'}} src="https://res.cloudinary.com/aptquirks/image/upload/v1682617324/WC_logo_rti9oh.png"></img></div>
              </div>          
            )}
      </>

 )
}