import React from 'react';

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContentWrapper">
        <p className="footerItem">Facilities USA</p>
        <p className="footerItem">420 Lexington Ave, 6th Floor, New York, NY 10170</p>
        <p className="footerItem">24/7 Dispatch (888) 782-3900</p>
        <p className="footerItem">Service@FacilitiesUSA.com</p>
        <p className="footerItem">www.FacilitiesUSA.com</p>
        <p className="footerItem">Copyright 2023 Facilities USA</p>
      </div>
    </div>
  )
}