import './App.css';
import React, {useEffect, useState} from 'react';
import {Nav} from './components/nav';
import NavContainer from './components/nav_container';
import {Home} from './components/home';
import { District } from './components/district';
import {Footer} from './components/footer';
import {Location} from './components/location';
import { Schedule } from './components/schedule';
import {SchedulesHomepage} from './components/schedules_homepage';
import { LocationsHomepage } from './components/locations_homepage';
import {CreateServiceTicket} from './components/create_service_ticket';
import { ServiceTickets } from './components/service_tickets';
import { SignUp } from './components/signup';
import {Login} from './components/login';
import { ProtectedRoute } from './components/protected_route';
import { ServiceTicket } from './components/service_ticket';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider, Navigate } from "react-router-dom";

const Root = () => {
  return (
    <>
      <div>
        <NavContainer />
      </div>

    <div>
      <Outlet />
    </div>

      <div>
        <Footer />
      </div>

  </>
)

}



const App = ({currentUser}) => {

  // const [user, setUser] = useState(null);

  useEffect(() => {
  }, [currentUser])

  const dispatch = useDispatch();

  const loginUser = (user) => {
    dispatch({ type: 'LOG_IN', payload: user });
  };

  const isLoggedIn = () => {
    const sessionUserData = sessionStorage.getItem("currentUser");
    const parsedUserData = JSON.parse(sessionUserData);
    if (currentUser && currentUser.userId > 0) {
      return true;
      //5951
    } else if (parsedUserData && parsedUserData.userId > 0) {
      loginUser(parsedUserData);
      return true;
    }
  }

  useEffect(() => {
    getIsLoggedIn();
  }, [currentUser])

  const getIsLoggedIn = async () => {
    if (!currentUser) {
      const sessionUserData = sessionStorage.getItem("currentUser");
      const parsedUserData = JSON.parse(sessionUserData);
      if (!!parsedUserData) {
        loginUser(parsedUserData);
      } else {
        const response = await fetch("/apiloggedIn");
        const isLoggedIn = response.json();
        if (isLoggedIn) {
          loginUser({currentUser: {userId: 1}});
        }
      }
    }
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route path="/" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <Home />
          </ProtectedRoute>
        } />
        <Route path="/district/:id" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <District />
          </ProtectedRoute>
        } />
        <Route path="/location/:id" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <Location />
          </ProtectedRoute>
        } />
                <Route path="/schedule/:id" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <Schedule />
          </ProtectedRoute>
        } />
        <Route path="/Schedules" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <SchedulesHomepage />
          </ProtectedRoute>
        } />
        <Route path="/locations" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <LocationsHomepage />
          </ProtectedRoute>
        } />
        <Route path="createTicket" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <CreateServiceTicket />
          </ProtectedRoute>
        } />
        <Route path="/serviceTickets" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <ServiceTickets />
          </ProtectedRoute>
        } />
          <Route path="/serviceTicket/:id" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <ServiceTicket />
          </ProtectedRoute>
        } />
        <Route path="/" index element={
          <ProtectedRoute isSignedIn={isLoggedIn()} path="/login">
            <Home />
          </ProtectedRoute>
        } />
        <Route path="/login" index element={
            <Login />
        } />
        {/* {isLoggedIn() && (
          <Route path="/signup" element={<SignUp />} />
        )} */}
      </Route>
    )
  )

  return (
      <div className="App">
        <RouterProvider router={router} />
      </div>
  );
}



export default App;
