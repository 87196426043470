import {
  AlignmentType,
  Document,
  HeadingLevel,
  ShadingType,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  Table,
  TableRow,
  TableCell,
  Footer,
  Header,
  WidthType,
  ImageRun,
  TextWrappingType,
  TextWrappingSide,
  UnderlineType,
  convertInchesToTwip,
  LevelFormat
} from "docx";
import { saveAs } from "file-saver";
import {image} from './base_64_image';
const tableData = [
  ['Location', 'Pick Up Type', 'Container Count', 'Container Size', 'Pick Up Freq.', 'Pick Up Days', 'Has Dumpster Lock'],
  ['Cell 1', 'Cell 2', 'Cell 3'],
  ['Cell 4', 'Cell 5', 'Cell 6']
];



export class DocumentCreator {

  async generateFromUrl(locationNumber, districtName, formattedSchedules, formattedSchedulesHeader) {
    const blob = await fetch(
      "https://res.cloudinary.com/aptquirks/image/upload/v1680292865/facilities_logo_fxaz7e.png"
    ).then(r => r.blob());

    const doc = new Document({
      styles: {
        default: {
            heading1: {
                run: {
                    size: 40,
                    bold: true,
                    color: "000000",
                    font: 'Calibri',
                    underline: {
                      type: UnderlineType.SINGLE,
                      color: "000000",
                  },
                },
                paragraph: {
                    spacing: {
                        after: 200,
                        before: 200
                    },
                },
            },
            heading2: {
                run: {
                    size: 35,
                    bold: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                        color: "000000",
                    },
                },
                paragraph: {
                    spacing: {
                        before: 200,
                        after: 200,
                    },
                },
            },
            heading3: {
              run: {
                  size: 30
              },
              paragraph: {
                  spacing: {
                      before: 200,
                      after: 200,
                  },
              },
          },
          heading4: {
            run: {
                size: 32,
                bold: true
            },
            paragraph: {
                spacing: {
                    before: 200,
                    after: 200,
                },
            },
        },
        heading5: {
          run: {
              size: 16,
          },
          paragraph: {
              spacing: {
                  before: 200,
              },
          },
      },
            listParagraph: {
                run: {
                    color: "#000000",
                },
            },
            document: {
                run: {
                    size: "11pt",
                    font: "Calibri",
                },
                paragraph: {
                    alignment: AlignmentType.LEFT,
                },
            },
        },
        paragraphStyles: [
            {
                id: "aside",
                name: "Aside",
                basedOn: "Normal",
                next: "Normal",
                run: {
                    color: "000000",
                    italics: true,
                },
                paragraph: {
                    indent: {
                        left: convertInchesToTwip(0.5),
                    },
                    spacing: {
                        line: 276,
                    },
                },
            },
            {
                id: "wellSpaced",
                name: "Well Spaced",
                basedOn: "Normal",
                quickFormat: true,
                paragraph: {
                    spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
                },
            },
            {
                id: "strikeUnderline",
                name: "Strike Underline",
                basedOn: "Normal",
                quickFormat: true,
                run: {
                    strike: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                    },
                },
            },
        ],
        characterStyles: [
            {
                id: "strikeUnderlineCharacter",
                name: "Strike Underline",
                basedOn: "Normal",
                quickFormat: true,
                run: {
                    strike: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                    },
                },
            },
        ],
    },
    numbering: {
        config: [
            {
                reference: "my-crazy-numbering",
                levels: [
                    {
                        level: 0,
                        format: LevelFormat.LOWER_LETTER,
                        text: "%1)",
                        alignment: AlignmentType.LEFT,
                    },
                ],
            },
        ],
    },
      sections: [
        {
          properties: {
            page: {
                margin: {
                    top: 1200,
                    right: 600,
                    bottom: 600,
                    left: 600,
                },
            },
        },
          footers: {
            default: new Footer({
                children: [new Paragraph({
                  heading: HeadingLevel.HEADING_5,
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "420 Lexington Ave, Suite 601 - New York, NY 10170 | T  +1 888-782-3900   F  +1 212-371-8504  E  Service@FacilitiesUSA.com",
                      font: 'Calibri',
                      color: '000000'
                    })
                    ]
                })],
            }),
        },
        headers: {
          default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: blob,
                      transformation: {
                        width: 280,
                        height: 40
                      }
                    })
                  ]
                }),
              ],
          }),
      },
      
          children: [
            new Paragraph({
              text: "",
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: `${districtName} #${locationNumber}`,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER
            }),
            new Paragraph({
              text: "Waste & Recycling Pickup Schedule:",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER
            }),
            new Paragraph({
              text: "",
              heading: HeadingLevel.HEADING_3,
            }),
            new Table({
              alignment: AlignmentType.CENTER,
              rows: formattedSchedulesHeader.map(rowData => {
                debugger;
                return new TableRow({
                  columnSpan: 5,
                  width: { size: 100, type: WidthType.PERCENTAGE },
                  children: rowData.map(cellData => {
                    return new TableCell({
                      children: [new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                          text: cellData,
                          bold: true
                        })
                      ]
                      })],
                      shading: {
                        fill: "d3d3d3",
                        type: ShadingType.SOLID,
                        color: "d3d3d3",
                    },
                    });
                  })
                });
              })
            }),
            new Table({
              alignment: AlignmentType.LEFT,
              rows: formattedSchedules.map(rowData => {
                return new TableRow({
                  columnSpan: 5,
                  width: { size: 100, type: WidthType.PERCENTAGE },
                  children: rowData.map(cellData => {
                    return new TableCell({
                      children: [new Paragraph(cellData)]
                    });
                  })
                });
              })
            }),
            // new Paragraph({
            //   text: "",
            //   heading: HeadingLevel.HEADING_3
            // }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "White Castle Manager(s):",
                  bold: true,
                  font: 'Calibri'
              })
              ]
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "Facilities USA (FUSA) manages the waste and recycling program for your district by coordinating with local trash and recycling haulers in your area.",
                  font: 'Calibri',
                  color: '000000'
                })
              ]
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_4,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: `When you need assistance, have a missed pick, need an extra pick up or need a schedule change or added services, please call 888-782-3900 24/7/365 or email us at`,
                  font: 'Calibri',
                  color: '000000'
                }),
                new TextRun({
                  text: " Service@FacilitiesUSA.com.",
                  color: "0000EE",
                  font: 'Calibri',
                  bold: false
                })
              ]
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_4,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "DO NOT CALL THE TELEPHONE NUMBER ON THE TRASH AND RECYCLING BINS.",
                  font: 'Calibri',
                  color: '000000'
              })
              ]
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "You should contact Facilities USA if you have any problems with your pickups or have any questions related to your service. If you feel that Facilities USA is not responding promptly or adequately to resolve a particular problem, please contact Donna Noonan at your regional office. Phone Number: (708) 458-4450, Email: NoonanD@WhiteCastle.com.",
                  font: 'Calibri',
                  color: '000000'
                })
              ]
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_4,
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: "Thank you.",
                  font: 'Calibri',
                  color: '000000'
              })
              ]
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: "",
            }),
            new Paragraph({
              text: "",
            }),
            
          ]
        },
      ]
    });

    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, `Location Poster - ${locationNumber}`);
      console.log("Document created successfully");
    });
  }
}
