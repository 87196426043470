const initialState = {
  currentUser: null
};

export const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "LOG_OUT":
      return {
        currentUser: null,
      };
    default:
      return state;
  }
};