import React from 'react';
import * as XLSX from 'xlsx';

export const ExcelDowloader = props => {
  const data = props.data ? props.data : [];
  const fileName = props.fileName ? props.fileName : "Excel_download.xlsx";
  const handleClickDownloadExcel = () => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, props.sheetName || "Sheet name")

    XLSX.writeFile(workBook, fileName);
  }

  return (
    <div>
      <button className="mainButton" onClick={handleClickDownloadExcel}>{props.buttonText || "Download Excel"}</button>
    </div>
  )
}