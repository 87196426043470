import React, { useState } from 'react';

const ISSUE_TYPES = [
  "Missed Pick-Up Trash", "Extra Pick-Up Trash", "Missed Pick-Up Recycling",
  "Extra Pick-Up Recycling", "On Call Pickup", "Roll-Off",
  "Container Over Loaded - Trash", "Container Over Loaded - Recycling", "New Store",
  "Hauler Change", "Vendor Cancellation", "Store Closing",
  "Equipment Repair", "Locks", "Violation",
  "Permit", "Tonnage Report", "Sanitation Sticker",
  "Illegal Dumping", "Equipment Request", "Service Request",
  "Vendor Request", "Client Request", "Contamination Charge - Recycling",
  "Container Removal", "Missed pickup - trash AND recycle", "Damage",
  "Extra Yardage", "Extra pickup Trash AND Recycle"
];

export const Dropdown = ({callback, data}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const finalData = data && data.length > 0 ? data : ISSUE_TYPES;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    callback(event.target.value);
  }

  return (
    <div>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="" disabled>--Select an Issue Type--</option>  
        {finalData.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}