import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

  // 30 = location number, 27 = region, 10 = location code
  // 15 = address, 7 = issue type, 32 = follow up date/time
  // 18 = reported by, 19 = status, 26 = notes

export const ServiceTickets = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async() => {
    const response = await fetch("/apiserviceTickets");
    const tickets = await response.json();
    if (tickets && tickets.sessionExpired) {
      logoutUser();
      return;
    }
    setTickets(tickets);
    setFilteredTickets(tickets);
  }

  const handleChangeInput = (e) => {
    const searchTerm = (e.target.value).toLowerCase();
    const filteredTickets = tickets.filter(item => {
      const searchStringticketCode = item && item[10] ? item[10].value : "";
      const searchStringAddress = item && item[15] ? item[15].value : "";
      const searchStringRequestedBy = item && item[18] ? item[18].value : "";
      const searchStringIssueType = item && item[7] ? item[7].value : "";
      const status = item && item[21] ? item[21].value : null;
      const statusText = !!status ? "Complete" : "Pending";
      return searchStringticketCode.toLowerCase().includes(searchTerm) || 
        searchStringAddress.toLowerCase().includes(searchTerm) || 
        searchStringRequestedBy.toLowerCase().includes(searchTerm) ||
        statusText.toLowerCase().includes(searchTerm) ||
        searchStringIssueType.toLowerCase().includes(searchTerm);
    });
    setFilteredTickets(filteredTickets)
  }

  const redirectToServiceTicketPage = (e, ticketId) => {
    navigate(`/serviceTicket/${ticketId}`, {state: {id: ticketId}});
  }

  const handleRedirectCreateTicket = () => {
    navigate("/createTicket");
  }

  return (
    <div className="tickets">
    <div className="ticketsList">
      <h2>White Castle tickets</h2>
      <div className="ticketsActionsWrapper">
        <div className="ticketsInputWrapper">
          <input placeholder="Search requests..." onChange={handleChangeInput}></input>
        </div>
      <button onClick={handleRedirectCreateTicket} className="mainButton">+ Create Service Request</button>
      </div>
    <table id="tableWrapper">
    <tbody>
    <tr>
      <th>Client Region</th>
      <th>Location Code</th>
      <th>Address</th>
      <th>Issue Type</th>
      <th>Reported By</th>
      <th>Status</th>
      <th>Notes</th>
    </tr>
      {filteredTickets && filteredTickets.length > 0 && filteredTickets.map((ticket, idx) => {
        const ticketId = ticket && ticket[3] ? ticket[3].value : null;
        const status = ticket && ticket[21] ? ticket[21].value : null;
        const statusText = !!status ? "Complete" : "Pending";
        const statusColor = !!status ? "LimeGreen" : "Orchid";
        return (
          <tr key={idx} onClick={(e) => redirectToServiceTicketPage(e, ticketId)} className="tableRow">
            <td className="tableCell">{ticket && ticket[27] ? ticket[27].value : ""}</td>
            <td className="tableCell">{ticket && ticket[10] ? ticket[10].value : ""}</td>
            <td className="tableCell">{ticket && ticket[15] ? ticket[15].value : ""}</td>
            <td className="tableCell">{ticket && ticket[7] ? ticket[7].value : ""}</td>
            <td className="tableCell">{ticket && ticket[18] ? ticket[18].value : ""}</td>
            <td className="tableCell"><p style={{backgroundColor: `${statusColor}`, padding: "7px", margin: "0px 4px", letterSpacing: "1.4"}}>{statusText}</p></td>
            <td className="tableCell">{ticket && ticket[26] ? ticket[26].value : ""}</td>
          </tr>
        )
      })}
      </tbody>
  </table>
  </div>
    </div>
    )
}