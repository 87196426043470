import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {ExcelDowloader} from './excel_download';
import LocationPosterButton from './location_poster';
import { useDispatch } from 'react-redux';

export const ServiceTicket = () => {


  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };


  const [hasData, setHasData] = useState(false);
  const [address, setAddress] = useState("");
  const [pickUpType, setPickUpType] = useState("");
  const [notes, setNotes] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [region, setRegion] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [recordId, setRecordId] = useState("");
  const [statusText, setStatusText] = useState("");
  const [statusTextColor, setStatusTextColor] = useState("");
  const [notesUpdate, setNotesUpdate] = useState("");


  const {state} = useLocation();
  const {id} = state;
  const ARROW = ">";
  const navigate = useNavigate();


  const fetchTicket = useCallback(async() => {
    const response = await fetch(`/apiserviceticket/${id}`);
    const ticket = await response.json();
    if (ticket && ticket.sessionExpired) {
      logoutUser();
      return;
    }
    const pickUpTypeLocal = ticket && ticket[7] ? ticket[7].value : "";
    const addressLocal = ticket && ticket[15] ? ticket[15].value : "";
    const locationCodeLocal = ticket && ticket[10] ? ticket[10].value : "";
    const requetedByLocal = ticket && ticket[18] ? ticket[18].value : "";
    const regionLocal = ticket && ticket[27] ? ticket[27].value : "";
    const currentNotesLocal = ticket && ticket[26] ? ticket[26].value : "";
    const recordIdLocal = ticket && ticket[3] ? ticket[3].value : "";
    const status = ticket && ticket[21] ? ticket[21].value : null;
    const statusTextLocal = !!status ? "Complete" : "Pending";
    const statusColorLocal = !!status ? "LimeGreen" : "Orchid";
    setPickUpType(pickUpTypeLocal);
    setAddress(addressLocal);
    setLocationCode(locationCodeLocal);
    setRequestedBy(requetedByLocal);
    setRegion(regionLocal);
    setNotes(currentNotesLocal);
    setRecordId(recordIdLocal);
    setStatusText(statusTextLocal);
    setStatusTextColor(statusColorLocal);
    setHasData(true);
  }, [id]);


  useEffect(() => {
    if (!hasData) {
      fetchTicket();
    }
  }, [hasData, notesUpdate, notes])

  const handleChangeNotesUpdate = (e) => {
    const notes = e.target.value;
    setNotesUpdate(notes);
  }

  const handleClickSaveNote = async () => {
    const data = {
      recordId: recordId,
      notes: `${requestedBy}: ${notesUpdate}`
    }

    const response = await fetch("/apiupdateServiceRequest", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': "application/json"
      }
    })
    const serviceRequest = await response.json();
      const updatedNotes = serviceRequest[0] && serviceRequest[0][26] ? serviceRequest[0][26].value : null;
      if (!!updatedNotes) {
        setNotes(updatedNotes);
        setNotesUpdate("")
      } else {
        setNotes(notes);
        setNotesUpdate("")
      }
    
  }

  return (
    <div className="Ticket">
      <div className="ticketDetailsWrapper">
      <h2>Service Request # {recordId}</h2>
        {hasData && (
          <>
            <div>
              <p className="fieldLabel">Status</p><p className="field" style={{width: "90px", textAlign: "left", backgroundColor: `${statusTextColor}`, padding: "7px", margin: "0px 4px", letterSpacing: "1.4"}}>{statusText}</p>
            </div>
            <div>
              <p className="fieldLabel">Region</p><p className="field">{region}</p>
            </div>
            <div>
              <p className="fieldLabel">Location Code</p><p className="field">{locationCode}</p>
            </div>
            <div>
              <p className="fieldLabel">Address</p><p className="field">{address}</p>
            </div>
            <div>
              <p className="fieldLabel">Pick-Up Type</p><p className="field">{pickUpType}</p>
            </div>
            <div>
              <p className="fieldLabel">Requested By</p><p className="field">{requestedBy}</p>
            </div>
            <div>
              <p className="fieldLabel">Notes</p><p className="field">{notes}</p>
            </div>
            <div className="inputWrapper">
              <p className="fieldLabel">Add Note</p><textarea onChange={handleChangeNotesUpdate} value={notesUpdate} className="textArea"></textarea>
            </div>
            {notesUpdate.length > 0 && (
              <div>
                <button onClick={handleClickSaveNote} className="mainButton">Save Note</button>
              </div>
            )}
          </>
        )}
        </div>
    </div>
  )
}