import React, { useState, useEffect } from 'react';

export const AutocompleteInput = ({
  handleLocationInputChange, 
  handleOptionClick,
  options,
  showOptions,
  inputText
}) => {
  return (
    <div className="autocompleteInput">
      <input
        type="text"
        value={inputText}
        onChange={handleLocationInputChange}
        placeholder='Search locations...'
      />
      {showOptions && (
        <ul className="autocompleteSearchOptionsWrapper">
          {options
            .filter(option => {
              const locationCode = !!(option && option[35]) ? option[35].value : "";
              const address = !!(option && option[8]) ? option[8].value : "";
              return locationCode.toLowerCase().includes(inputText.toLowerCase()) || address.toLowerCase().includes(inputText.toLowerCase());
            })
            .map(option => {
              const locationCode = !!(option && option[35]) ? option[35].value : "";
              const address = !!(option && option[8]) ? option[8].value : "";

              return (
                <li className="autocompleteSearchItem" key={locationCode} onClick={() => handleOptionClick(option)}>
                  <div className="autocompleteLocationCode">
                    <p style={{fontWeight: "bold"}}>Location #</p>
                    <p>{locationCode}</p>
                  </div>
                  <div className="autocompleteAddress">
                    <p style={{fontWeight: "bold"}}>Address</p>
                    <p>{address}</p>
                  </div>
                </li>
              )
            })}
        </ul>
      )}
    </div>
  );
};
