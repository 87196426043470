import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const SchedulesHomepage = () => {

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const [schedules, setSchedules] = useState([]);
  const [hasScedules, setHasSchedules] = useState(false);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const ARROW = ">";
  const navigate = useNavigate();

  useEffect(() => {
    fetchSchedules();
  }, [])

    const fetchSchedules = async () => {
      const response = await fetch(`/apischedules`);
      const schedules = await response.json();
      if (schedules && schedules.length > 0) {
        setSchedules(schedules);
        setHasSchedules(true);
        setFilteredSchedules(schedules);
      }
    }

    const redirectToschedulePage = (e, scheduleId) => {
      navigate(`/schedule/${scheduleId}`, {state: {id: scheduleId}});
    }

    const handleChangeInput = (e) => {
      const searchTerm = e.target.value;
      const filteredSchedules = schedules.filter(item => {
        const searchStringLocationCode = item && item[21] ? item[21].value : "";
        const searchStringAddress = item && item[36] ? item[36].value : "";
        const searchStringType = item && item[20] ? item[20].value : "";

        return searchStringLocationCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
        searchStringAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
        searchStringType.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredSchedules(filteredSchedules)
    }

  return (
    <div className="schedules">
    <div className="scheduleDetailsWrapper">
    <h2>All Waste & Recycling Schedules</h2>
    <div className="searchInputWrapper inputWrapper">
      <input placeholder="Search records..." onChange={handleChangeInput}></input>
    </div>
    <table id="tableWrapper">
          <tbody>
          <tr>
            <th>White Castle Location Number</th>
            <th>Location Code</th>
            <th>Address</th>
            <th>Pick-Up Type</th>
            <th>Container Count</th>
            <th>Container Size</th>
            <th>Pick-Up Freq.</th>
            <th>Pick-Up Days</th>
            <th>Has Dumpster Lock</th>
          </tr>
            {hasScedules && filteredSchedules.map((schedule, idx) => {
              const scheduleId = schedule && schedule[3].value ? schedule[3].value : null;
              const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : "";
              const hasDumpsterLockText =  hasDumpsterLock === '1' ? "Yes" :"";
              return (
                <tr key={idx} onClick={(e) => redirectToschedulePage(e, scheduleId)} className="tableRow">
                  <td className="tableCell">{schedule && schedule[66] ? schedule[66].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[21] ? schedule[21].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[36] ? schedule[36].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[20] ? schedule[20].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[33] ? schedule[33].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[15] ? schedule[15].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[16] ? schedule[16].value : ""}</td>
                  <td className="tableCell">{schedule && schedule[61] ? schedule[61].value : ""}</td>
                  <td className="tableCell">{hasDumpsterLockText}</td>
                </tr>
              )
            })}
            </tbody>
        </table>
    </div>
    </div>
  )
}