import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { LocationSearch } from './location_search';
import { useDispatch } from 'react-redux';

export const LocationsHomepage = () => {

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([])

  useEffect(() => {
    fetchLocations();
  }, [])

  const fetchLocations = async() => {
    const response = await fetch("/apilocations/");
    const locations = await response.json();
    if (locations && locations.sessionExpired) {
      logoutUser();
      return;
    }
    setLocations(locations);
    setFilteredLocations(locations)
  }

  const redirectToLocationPage = (e, locationId) => {
    navigate(`/location/${locationId}`, {state: {id: locationId}});
  }

  const handleChangeInput = (e) => {
    const searchTerm = e.target.value;
    const filteredLocations = locations.filter(item => {
      const searchStringLocationCode = item && item[35] ? item[35].value : "";
      const searchStringAddress = item && item[8] ? item[8].value : "";
      return searchStringLocationCode.toLowerCase().includes(searchTerm.toLowerCase()) || searchStringAddress.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredLocations(filteredLocations)
  }

  // const handleRedirectCreateTicket = () => {
  //   navigate("/createTicket");
  // }

  return (
  <div className="locations">
  <div className="locationsList">
    <h2>White Castle Locations</h2>
    <div className="searchInputWrapper inputWrapper">
      <input placeholder="Search records..." onChange={handleChangeInput}></input>
    </div>
  <table id="tableWrapper">
  <tbody>
  <tr>
    <th>White Castle Location Number</th>
    <th>Location Code</th>
    <th>Address</th>
    <th>Location Phone #</th>
    {/* <th>Add Additional Pick-Up</th> */}
  </tr>
    {filteredLocations.length > 0 && filteredLocations.map((location, idx) => {
      const locationId = location && location[3] ? location[3].value : null;
      return (
        <tr key={idx} onClick={(e) => redirectToLocationPage(e, locationId)} className="tableRow">
          <td className="tableCell">{location && location[66] ? location[66].value : ""}</td>
          <td className="tableCell">{location && location[35] ? location[35].value : ""}</td>
          <td className="tableCell">{location && location[8] ? location[8].value : ""}</td>
          <td className="tableCell">{location && location[36] ? location[36].value : ""}</td>
          {/* <td className="tableCell"><button onClick={handleRedirectCreateTicket} className="mainButton">+ Create Service Request</button></td> */}
        </tr>
      )
    })}
    </tbody>
</table>
</div>
  </div>
  )
}