import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    // checkIfUserSessionExists()
  }, [])

  const checkIfUserSessionExists = async () => {
    const response = await fetch("/apiloggedIn");
    const loggedIn = await response.json()
    const user = loggedIn ? { userName: "user", userLastName: "last name", userId: 1 } : null;
    dispatch({ type: 'LOG_IN', payload: user });
    if (user && user.userId > 0) {
      navigate("/");
    }
  }
  
  const loginUser = (user) => {
    dispatch({ type: 'LOG_IN', payload: user });
  };

  const handleLogin = () => {
    if (!hasRequiredData()) {
      console.log("mising required data for login");
      return;
    }
    const loginData = {
      email: email, 
      password: password
    }
    fetch('/apilogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (!!(data && data.userId)) {
        loginUser(data)
        sessionStorage.setItem("currentUser", JSON.stringify(data));
        navigate("/", {loggedIn: true});
      }
    })
    .catch(error => {
      console.error(error);
      navigate("/login");
    });
  }

  const hanelChangeInput = (e, callback) => {
    const input = e.target.value;
    callback(input);
  }
  const hasRequiredData = () => {
    return !!email && !!password;
  };

  return (
    <div className="signup">
    <img className="fusaLogoLogin" src="https://res.cloudinary.com/aptquirks/image/upload/v1680292865/facilities_logo_fxaz7e.png"></img>
    <h1 className="loginTitle">Login</h1>
    <div className="signupInputsWrapper">
        <input placeholder="Email or username..." className="inputAuth" onChange={(e) => hanelChangeInput(e, setEmail)}></input>
        <input placeholder="Password..." type="password" className="inputAuth" onChange={(e) => hanelChangeInput(e, setPassword)}></input>
        <button className="mainButton loginButton" onClick={handleLogin}>Login</button>
    </div>
  </div>
  )
}