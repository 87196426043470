import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {ExcelDowloader} from './excel_download';
import { useDispatch } from 'react-redux';

  // 66 = location number, 36 = address, 21 = location code, 
  // 20 = pick up type, 33 = container count, 15 container size, 
  // 16 = pick up freq., 61 = pick up days, 44 = has dumpster lock

export const District = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const [hasDistrictData, setHasDistrictData] = useState(false);
  const [districtName, setDistrictName] = useState("");
  const [districtContact, setDistrictContact] = useState("");
  const [districtContactPhone, setDistrictContactPhone] = useState("");
  const [districtContactEmail, setDistrictContactEmail] = useState("");
  const [districtCode, setDistrictCode] = useState("");
  const [hasLocations, setHasLocations] = useState(false);
  const [locations, setLocations] = useState([]);
  const [hasScedules, setHasSchedules] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [processedSchedules, setProcessedSchedules] = useState([]);
  const [processedLocations, setProcessedLocations] = useState([]);
  const {state} = useLocation();
  const {id} = state;
  const ARROW = ">";

  const fetchDistrict = useCallback(async() => {
    const response = await fetch(`/apidistrict/${id}`);
    const districtArray = await response.json();
    // session expired
    if (districtArray && districtArray.sessionExpired) {
      logoutUser();
      return;
    }
    const district = districtArray[0];
    const districtName = district && district[21] ? district[21].value : "";
    const districtContact = district && district[28] ? district[28].value : "";
    const districtContactPhone = district && district[30] ? district[30].value : "";
    const districtContactEmail = district && district[33] ? district[33].value : "";
    const districtCode = district && district[6] ? district[6].value : "";
    setDistrictName(districtName)
    setDistrictContact(districtContact)
    setDistrictContactPhone(districtContactPhone)
    setDistrictContactEmail(districtContactEmail)
    setHasDistrictData(true);
    setDistrictCode(districtCode);
  }, [id])

  const fetchLocations = useCallback(async() => {
    console.log("Fetching locations 65982");
    const response = await fetch(`/apilocations/${districtCode}`);
    const locations = await response.json();
    if (locations && locations.length > 0) {
      setLocations(locations);
      setHasLocations(true);
      processLocationsForExcelDownload(locations);
    }
  }, [districtCode])

  const fetchSchedules = useCallback(async() => {
    const response = await fetch(`/apischedules/${id}`);
    const schedules = await response.json();
    if (schedules && schedules.length > 0) {
      setSchedules(schedules);
      setHasSchedules(true);
      processSchedulesForExcelDownload(schedules)
    }
  }, [id])

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (!hasDistrictData) {
      fetchDistrict();
    }
    if (schedules.length === 0) {
      fetchSchedules();
    }
    if (locations.length === 0) {
      fetchLocations();
    }

  }, [locations, schedules, hasDistrictData, fetchDistrict, fetchLocations, fetchSchedules])

  const processSchedulesForExcelDownload = schedules => {
    const processedSchedules = [];
    schedules.forEach(schedule => {
      const nextSchedule = {};
      const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : "";
      const hasDumpsterLockText =  !!hasDumpsterLock ? "Yes" :"No";
      nextSchedule["White Castle Location Number"] = schedule && schedule[66] ? schedule[66].value : "";
      nextSchedule["Address"] = schedule && schedule[36] ? schedule[36].value : "";
      nextSchedule["Pick-Up Type"] = schedule && schedule[20] ? schedule[20].value : "";
      nextSchedule["Container Count"] = schedule && schedule[33] ? schedule[33].value : "";
      nextSchedule["Container Size"] = schedule && schedule[15] ? schedule[15].value : "";
      nextSchedule["Pick-Up Freq."] = schedule && schedule[16] ? schedule[16].value : "";
      nextSchedule["Pick-Up Days"] = schedule && schedule[61] ? schedule[61].value : "";
      nextSchedule["Has Dumpster Lock"] = hasDumpsterLockText;
      processedSchedules.push(nextSchedule);
    })
    setProcessedSchedules(processedSchedules)
  }

  const processLocationsForExcelDownload = locations => {
    const processedLocations = [];
    locations.forEach(location => {
      const nextLocation = {};
      nextLocation["White Castle Location Number"] = location && location[66] ? location[66].value : "";
      nextLocation["Location Code"] = location && location[35] ? location[35].value : "";
      nextLocation["Address"] = location && location[8] ? location[8].value : "";
      nextLocation["Location Phone"] = location && location[36] ? location[36].value : "";
      processedLocations.push(nextLocation);
    })
    setProcessedLocations(processedLocations)
  }

  const redirectToLocationPage = (e, locationId) => {
    navigate(`/location/${locationId}`, {state: {id: locationId}});
  }

  const redirectToschedulePage = (e, scheduleId) => {
    navigate(`/schedule/${scheduleId}`, {state: {id: scheduleId}});
  }

  return (
    <div className="district">
      {hasDistrictData && (
        <>
        <div className="districtDetailsWrapper">
          <h2>White Castle Districts <strong className="arrow">{ARROW}</strong> {districtName}</h2>
          <div>
            <div>
              <p className="fieldLabel">District Name: </p><p className="field">{districtName}</p>
            </div>
            <div>
              <p className="fieldLabel">District Contact: </p><p className="field">{districtContact}</p>
            </div>
            <div>
              <p className="fieldLabel">District Contact Phone: </p><p className="field">{districtContactPhone}</p>
            </div>
            <div>
              <p className="fieldLabel">District Contact Email: </p><p className="field">{districtContactEmail}</p>
            </div>
          </div>
        {processedSchedules.length > 0 && (<ExcelDowloader 
          fileName={`${districtName}_schedules.xlsx`} 
          buttonText="Download Schedules Excel"
          sheetName="Schedules"
          data={processedSchedules}  
        />)}
        {processedLocations.length > 0 && (<ExcelDowloader 
          fileName={`${districtName}_locations.xlsx`} 
          buttonText="Download Locations Excel"
          sheetName="Locations"
          data={processedLocations}  
        />)}
        </div>
  <div className="schedulesList">
  <h2>Waste & Recycling Schedules</h2>
  <table id="tableWrapper">
        <tbody>
        <tr>
          <th>White Castle Location Number</th>
          <th>Address</th>
          <th>Pick-Up Type</th>
          <th>Container Count</th>
          <th>Container Size</th>
          <th>Pick-Up Freq.</th>
          <th>Pick-Up Days</th>
          <th>Has Dumpster Lock</th>
        </tr>
          {hasScedules && schedules.map((schedule, idx) => {
            const scheduleId = schedule && schedule[3].value ? schedule[3].value : null;
            const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : "";
            const hasDumpsterLockText =  !!hasDumpsterLock ? "Yes" :"";
            return (
              <tr key={idx} onClick={(e) => redirectToschedulePage(e, scheduleId)} className="tableRow">
                <td className="tableCell">{schedule && schedule[66] ? schedule[66].value : ""}</td>
                <td className="tableCell">{schedule && schedule[36] ? schedule[36].value : ""}</td>
                <td className="tableCell">{schedule && schedule[20] ? schedule[20].value : ""}</td>
                <td className="tableCell">{schedule && schedule[33] ? schedule[33].value : ""}</td>
                <td className="tableCell">{schedule && schedule[15] ? schedule[15].value : ""}</td>
                <td className="tableCell">{schedule && schedule[16] ? schedule[16].value : ""}</td>
                <td className="tableCell">{schedule && schedule[61] ? schedule[61].value : ""}</td>
                <td className="tableCell">{hasDumpsterLockText}</td>
              </tr>
            )
          })}
          </tbody>
      </table>
  </div>
        <div className="locationsList">
          <h2>White Castle Locations</h2>
        <table id="tableWrapper">
        <tbody>
        <tr>
          <th>White Castle Location Number</th>
          <th>Location Code</th>
          <th>Address</th>
          <th>Location Phone #</th>
          <th>Add Additional Pick-Up</th>
        </tr>
          {hasLocations && locations.map((location, idx) => {
            const locationId = location && location[3].value ? location[3].value : null;
            return (
              <tr key={idx} onClick={(e) => redirectToLocationPage(e, locationId)} className="tableRow">
                <td className="tableCell">{location && location[66] ? location[66].value : ""}</td>
                <td className="tableCell">{location && location[35] ? location[35].value : ""}</td>
                <td className="tableCell">{location && location[8] ? location[8].value : ""}</td>
                <td className="tableCell">{location && location[36] ? location[36].value : ""}</td>
                <td className="tableCell">{"Add and extra pick-up"}</td>
              </tr>
            )
          })}
          </tbody>
      </table>
        </div>
        </>
      )}
    </div>
  )
}