import React, {useEffect, useState} from 'react';
import {Dropdown} from './issue_type_dropdown';
import { useDispatch } from 'react-redux';
import {AutocompleteInput} from './autocomplete_search';
import {useNavigate} from 'react-router-dom';

// 30 - location number, 27 - district, 15 - address
// 10 - location code, 41 - location phone, issue type - 7,
// 18 - reported by, 41 - hauler phone, 43 - hauler cell
// 26 - notes, 1 - date ceated, 32 - follow up date/time

export const CreateServiceTicket = ({locationId}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const [issueType, setIssueType] = useState("");
  const [reportedBy, setReportedBy] = useState("");
  const [locations, setLocations] = useState([]);
  const [inputText, setInputText] = useState('');
  const [notes, setNotes] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [relatedClientLocation, setRelatedClientLocation] = useState(null);
  const [options, setOptions] = useState(locations);

  useEffect(() => {
    if (locations.length === 0) {
      fetchLocations();
    }
  }, [])

  const handleLocationInputChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);
    setShowOptions(newText !== '');
  };

  const activeButMissingRequiredInformation = () => {
    const hasSomeData = relatedClientLocation > 0 || reportedBy.length > 0 || issueType.length > 0;
    const missingSomeData = relatedClientLocation === null || reportedBy.length === 0 || issueType.length === 0;

    if (hasSomeData && missingSomeData) {
      return true;
    } else {
      return false;
    }
  }

  const handleLocationOptionClick = (option) => {
    const locationCode = !!(option && option[35]) ? option[35].value : "";
    const recordId = !!(option && option[3]) ? option[3].value : null;
    setInputText(locationCode);
    setShowOptions(false);
    setRelatedClientLocation(recordId);
  };

  const fetchLocations = async() => {
    const response = await fetch("/apilocations/");
    const locations = await response.json();
    if (locations && locations.sessionExpired) {
      logoutUser();
      return;
    }
    setLocations(locations);
  }

  const handleChangeDropdown = (issueType) => {
    setIssueType(issueType);
  }

  const handleChangeNotes = e => {
    const notes = e.target.value;
    setNotes(notes);
  }

  const handleChange = (event) => {
    setReportedBy(event.target.value);
  }

  const missingRequiredData = () => relatedClientLocation === null || reportedBy.length === 0 || issueType.length === 0;


  const createServiceTicket = async () => {
    if (missingRequiredData()) {
      return;
    }
    const data = {
      issueType: issueType,
      reportedBy: reportedBy,
      relatedClientLocation: relatedClientLocation,
      notes: notes
    }

    const response = await fetch("/apicreateServiceRequest", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': "application/json"
      }
    })
    const serviceRequest = await response.json();
      const id = serviceRequest[0] && serviceRequest[0][3] ? serviceRequest[0][3].value : null;
      if (id > 0) {
        navigate(`/serviceticket/${id}`, {state: {id: id}})
      } else {
        navigate("/createTicket")
      }
    
  }

  return (
    <div className="serviceTicket">
    <h2>Create a Service Request</h2>
    <div className="serviceTicketWrapper">
      <div className="createTicketFormItem">
      <div className="labelAndStarWrapper">
        <p className="inputLabel" htmlFor="my-input">Location Code</p>
        <p className="requiredStar">*</p>
      </div>
        <AutocompleteInput
          handleLocationInputChange={handleLocationInputChange}
          handleOptionClick={handleLocationOptionClick}
          options={locations}
          inputText={inputText}
          showOptions={showOptions}
        />
      </div>
      <div className="dropdownWrapper createTicketFormItem">
      <div className="labelAndStarWrapper">
        <p className="inputLabel" htmlFor="my-input">Issue Type</p>
        <p className="requiredStar">*</p>
      </div>
        <Dropdown callback={handleChangeDropdown} />
      </div>
      <div className="createTicketFormItem">
      <div className="labelAndStarWrapper">
        <p className="inputLabel" htmlFor="my-input">Reported By:</p>
        <p className="requiredStar">*</p>
      </div>
        <input placeholder='Enter your name...' type="text" id="my-input" value={reportedBy} onChange={handleChange} />
      </div>
      <div className="createTicketFormItem">
        <p className="inputLabel" htmlFor="my-input">Notes:</p>
        <textarea placeholder='Add any additional notes...' className="notesTextArea" type="text" id="my-input" value={notes} onChange={handleChangeNotes}></textarea>
      </div>
      {activeButMissingRequiredInformation() && (
        <p className="requiredInfoWarning">Please enter all the required information before saving *</p>
      )}
      <button className="mainButton" onClick={createServiceTicket}>Save & Close</button>
    </div>
    </div>
  )

}