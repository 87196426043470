import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {ExcelDowloader} from './excel_download';
import LocationPosterButton from './location_poster';
import { useDispatch } from 'react-redux';

export const Location = () => {


  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch({ type: 'LOG_OUT', payload: null });
    sessionStorage.setItem('currentUser', null);
    window.location = "/";
  };

  const [hasLocationData, setHasLocationData] = useState(false);
  const [locationPhone, setLocationPhone] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [schedules, setSchedules] = useState([])
  const [formattedSchedules, setFormattedSchedules] = useState([])
  const [hasSchedules, setHasSchedules] = useState(false);
  const [districtName, setDistrictName] = useState("");
  const [locationNumber, setLocationNumber] = useState("");
  const {state} = useLocation();
  const {id} = state;
  const ARROW = ">";
  const navigate = useNavigate();


  const fetchLocation = useCallback(async() => {
    const response = await fetch(`/apilocation/${id}`);
    const location = await response.json();
    if (location && location.sessionExpired) {
      logoutUser();
      return;
    }
    const phone = location && location[36] ? location[36].value : "";
    const address = location && location[8] ? location[8].value : "";
    const nameNumber = location && location[66] ? location[66].value : "";
    const districtNameLocal = location && location[55] ? location[55].value : "";
    const locationNumberLocal = location && location[51] ? location[51].value : "";
    setLocationPhone(phone);
    setLocationAddress(address);
    setLocationName(nameNumber);
    setDistrictName(districtNameLocal);
    setLocationNumber(locationNumberLocal);
    setHasLocationData(true);
  }, [id]);

  const fetchSchedules = useCallback(async() => {
    const response = await fetch(`/apischedules/${id}`);
    const schedules = await response.json();
    if (schedules && schedules.length > 0) {
      setSchedules(schedules);
      setHasSchedules(true);
      formatSchedules(schedules);
    }
  }, [id])

  const formatSchedules = schedules => {

    const formatSchedulesFinal = [
    ];
    schedules && schedules.forEach(schedule => {
      const address = schedule && schedule[36] ? schedule[36].value : "";
      const pickUpType = schedule && schedule[20] ? schedule[20].value : "";
      const containerCount = schedule && schedule[33] ? schedule[33].value : "";
      const containerSize = schedule && schedule[15] ? schedule[15].value : "";
      const pickUpFreq = schedule && schedule[16] ? schedule[16].value : "";
      const pickUpDays = schedule && schedule[61] ? schedule[61].value : "";
      const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : "";
      const hasDumpsterLockText =  hasDumpsterLock === '1' ? "Yes" :"No";
      formatSchedulesFinal.push([address, pickUpType, containerCount, containerSize, pickUpFreq, pickUpDays, hasDumpsterLockText]);
    })
    setFormattedSchedules(formatSchedulesFinal)
  }

  useEffect(() => {
    if (hasLocationData === false) {
      fetchLocation();
    }
    if (schedules.length === 0) {
      fetchSchedules();
    }
    if (formattedSchedules.length === 0) {
      formatSchedules()
    }
  }, [hasLocationData, schedules, fetchLocation, fetchSchedules])

  const redirectToschedulePage = (e, scheduleId) => {
    navigate(`/schedule/${scheduleId}`, {state: {id: scheduleId}});
  }

  const handleRedirectCreateTicket = () => {
    navigate("/createTicket");
  }

  return (
    <div className="location">
      {hasLocationData && (
      <div className="locationDetailsWrapper">
        <h2>White Castle Location <strong className="arrow">{ARROW}</strong> {!!locationName && locationName}</h2>
      <div>
        <p className="fieldLabel">Location Number: </p><p className="field">{locationName}</p>
      </div>
      <div>
        <p className="fieldLabel">district Name</p><p className="field">{districtName}</p>
      </div>
      <div>
        <p className="fieldLabel">Location Phone </p><p className="field">{locationPhone}</p>
      </div>
      <div>
        <p className="fieldLabel">Address </p><p className="field">{locationAddress}</p>
      </div>
        <LocationPosterButton 
          locationNumber={locationNumber} 
          districtName={districtName}
          formattedSchedules={formattedSchedules}
        />
        <button onClick={handleRedirectCreateTicket} className="mainButton">+ Create Service Request</button>

    </div>
    
    )}
  <div className="schedulesList">
  <h2>Waste & Recycling Schedules</h2>
  <table id="tableWrapper">
        <tbody>
        <tr>
          <th>White Castle Location Number</th>
          <th>Location Code</th>
          <th>Address</th>
          <th>Pick-Up Type</th>
          <th>Container Count</th>
          <th>Container Size</th>
          <th>Pick-Up Freq.</th>
          <th>Pick-Up Days</th>
          <th>Has Dumpster Lock</th>
        </tr>
          {hasSchedules && schedules.map((schedule, idx) => {
            const scheduleId = schedule && schedule[3].value ? schedule[3].value : null;
            const hasDumpsterLock = schedule && schedule[44] ? schedule[44].value : "";
            const hasDumpsterLockText =  hasDumpsterLock === '1' ? "Yes" :"No";
            return (
              <tr key={idx} onClick={(e) => redirectToschedulePage(e, scheduleId)} className="tableRow">
                <td className="tableCell">{schedule && schedule[66] ? schedule[66].value : ""}</td>
                <td className="tableCell">{schedule && schedule[21] ? schedule[21].value : ""}</td>
                <td className="tableCell">{schedule && schedule[36] ? schedule[36].value : ""}</td>
                <td className="tableCell">{schedule && schedule[20] ? schedule[20].value : ""}</td>
                <td className="tableCell">{schedule && schedule[33] ? schedule[33].value : ""}</td>
                <td className="tableCell">{schedule && schedule[15] ? schedule[15].value : ""}</td>
                <td className="tableCell">{schedule && schedule[16] ? schedule[16].value : ""}</td>
                <td className="tableCell">{schedule && schedule[61] ? schedule[61].value : ""}</td>
                <td className="tableCell">{hasDumpsterLockText}</td>
              </tr>
            )
          })}
          </tbody>
      </table>
  </div>
    </div>
  )
}